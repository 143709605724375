.sidebar-wrapper {
  position: relative;
  display: flex;
  flex-direction: row;

  &:hover {
    .sidebar-opener {
      background-color: #4092ff;
    }
  }

  .sidebar-opener {
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    width: 4px;
    cursor: pointer;

    &:hover {
      -webkit-box-shadow: inset -12px 1px 5px -4px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: inset -12px 1px 5px -4px rgba(0, 0, 0, 0.1);
      box-shadow: inset -12px 1px 5px -4px rgba(0, 0, 0, 0.1);
    }
  }

  &.minimized {
    .sidebar-opener {
      right: 0;
    }
  }

  .sidebar {
    padding: 15px;
    background-color: var(--primary);
    width: 100%;

    .nav-pills {
      display: flex;
      flex-direction: column;

      .nav-item {
        .nav-link {
          text-align: left;
          display: flex;
          align-items: center;
          color: white;
          font-size: 14px;
        }
      }
    }

    .logo {
      display: flex;
      align-items: center;
      color: white;

      margin-bottom: 10px;

      h4 {
        margin: 0;
      }

      img {
        width: 54px;
        height: 54px;
        border: none;
        margin-right: 10px;
      }
    }

    &.minimized {
      .nav-title {
        display: none;
      }

      .nav-pills {
        .nav-item {
          .nav-link {
            height: 37px;
            margin: 0;

            i {
              margin-left: 2px;
            }
          }
        }
      }

      .logo {
        display: flex;
        align-items: center;

        img {
          width: 54px;
          height: 54px;
          border: none;
          margin-right: 0;
        }
      }
    }
  }
}

.custom-user-block {
  display: none;
}

@media screen and (max-width: 768px) {
  .custom-user-block {
    display: block;
  }

  .sidebar-wrapper {
    display: flex;
    height: 100vh;
    position: fixed;
    z-index: 100;

    &.minimized {
      left: -90px;
    }
  }

  .sidebar-opener {
    display: none;
  }
}
