.general-wrapper.authorized {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;

  * > {
    height: 100%;
  }

  // sidebar
  .sidebar-wrapper {
    width: 270px;

    &.minimized {
      width: 90px;
    }
  }

  //entire content
  .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 15px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .general-wrapper.authorized {
    .content-wrapper {
      padding-top: 50px;
    }
  }
}