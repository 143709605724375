.shadow-box-login {
  -webkit-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.3);


  background-color: white;
  height: 100vh;
}

.color {
  background-color: var(--primary);
}

.background-texture {
  background-image: url(background.png);
}

.fullSizeContainerWithVerticalCenter {
  display: block;
}

@media screen and (max-width: 768px) {
  .fullSizeContainerWithVerticalCenter {
    padding-top: 50px;
  }

}

@media screen and (min-width: 768px) {
  .fullSizeContainerWithVerticalCenter {
    padding-top: 150px;
  }


}
