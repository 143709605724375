/* import only the necessary Bootstrap files */
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

/* -------begin customization-------- */
/* change the primary theme color to red */
:root {
  --primary: #093aac;
}

.nav-link.active .nav-title.container,
.nav-link.active .fa {
  color: var(--primary) !important;
}

.nav-pills .nav-item .nav-link.active {
  background-color: white;
}

/* Default margins */
p,
ul,
ol,
dl,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
form,
table {
  margin: 12px 0 0 0;
}

/* Links */
a {
  color: #0052cc;
  text-decoration: none;
}

a:hover {
  color: #0065ff;
  text-decoration: underline;
}

a:active {
  color: #0747a6;
}

/* -------end customization-------- */

/* finally, import Bootstrap to set the changes! */
@import '~bootstrap/dist/css/bootstrap.min.css';
