.custom-input {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgb(250, 251, 252);
  box-sizing: border-box;
  color: rgb(9, 30, 66);
  display: flex;
  font-size: 14px;
  -webkit-box-pack: justify;
  justify-content: space-between;
  line-height: 1;
  max-width: 100%;
  overflow-wrap: break-word;
  border-color: rgb(223, 225, 230);
  border-radius: 3px;
  border-style: solid;
  flex: 1 0 auto;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out 0s, border-color 0.2s ease-in-out 0s;
  border-width: 2px;
  padding: 1px;

  input {
    box-sizing: border-box;
    color: inherit;
    cursor: inherit;
    font-family: inherit;
    font-size: 14px;
    min-width: 0;
    width: 100%;
    background: transparent;
    border: none;
    outline: none;
    box-shadow: none;

    &:active,
    &:focus,
    &:focus-within,
    &:focus-visible {
      outline: none;
      border: none;
      box-shadow: none;
      background-color: transparent;
    }
  }
}

.custom-label {
  text-align: left;
  width: 100%;
}
