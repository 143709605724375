.checkbox {
  display: flex;
  flex-direction: row;
  //justify-content: center;
  align-items: center;

  margin-right: 10px;

  .check {
    margin-right: 10px;
  }

  .label {
    margin: 0;
  }
}
