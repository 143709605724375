.section {
  position: relative;
  padding: 15px 10px 5px 10px;
  border: 1px solid #093AAB;
  border-radius: 5px;
  margin: 10px 10px;
  background-color: rgba(9, 58, 171, 0.04) !important;
}

.redTitle {
  background-color: #bd2130 !important;
  border: 1px solid #bd2130 !important;
}

.redBorder {
  border: 1px solid #bd2130;
  background-color: rgba(189, 33, 48, 0.05) !important;
}

.violetTitle {
  background-color: #6f42c1 !important;
  border: 1px solid #6f42c1 !important;
}

.violetBorder {
  border: 1px solid #6f42c1;
  background-color: rgba(111, 66, 193, 0.05) !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.title {
  position: absolute;
  top: -12px;
  left: 2px;

  padding: 1px 5px;
  border: 1px solid #093AAB;
  border-radius: 50px;
  background-color: #093AAB;
  color: white;
}

.songBase {
  display: block;
}

.chord {
  color: #f4503a;
  font-family: OpenSans, sans-serif;
  font-weight: bold;
  line-height: 7px;
  margin-top: 10px;
}

.textLine {
  padding: 0;
  margin: 0;
}

.text {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;

  & > span {
    width: 100%;
  }
}