.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.label {
  margin-left: 4px;
}

.switch {
  display: flex;
  flex-direction: row;
  width: 34px;
  padding: 2px;
  box-sizing: border-box;
  -webkit-transition: all 3s;
  transition: all 0.3s;
  border-radius: 540px;
  border: 2px solid #007bff;
}

.active {
  background-color: #007bff;
  flex-direction: row-reverse;
}

.toggle {
  width: 4px;
  height: 12px;
  background: #ffffff;
  border-radius: 100%;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  left: 0;
  position: relative;
  padding: 2px 4px;
  border: 2px solid #007bff;
}