.tag {
  cursor: pointer;
  background: #0065ff;
  padding: 2px 8px 4px 8px;
  border-radius: 20px;
  color: #fff;
  margin-right: 10px;
}

.notSelected {
  background: #fff;
  border: 1px solid #0065ff;
  color: #0065ff;
}