.wrapper {

}

@media screen and (max-width: 770px) {
  .wrapper {
    margin-bottom: 30px;
  }
}

