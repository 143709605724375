.paginationBase {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.miniPaginationBase {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
}

.miniPagination {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.limitSection {
    width: 100px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    margin-right: 20px;
    padding-top: 10px;
}

.limitSection span {
    margin-right: 10px;
}