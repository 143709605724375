.add-to-list {
  position: relative;

  .popup {
    z-index: 10000;

    position: absolute;

    -webkit-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.75);

    left: -1px;
    top: 42px;

    width: 150px;

    border-radius: 3px;

    color: #000;
    background: white;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        display: flex;

        cursor: pointer;

        justify-content: center;
        align-items: center;

        height: 38px;

        &:hover {
          background-color: #cbfdea;
        }
      }
    }
  }
}
