.header {
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background: var(--primary);

  z-index: 99999;

  &.offset {
    width: calc(100vw - 270px);
    left: 270px;
  }
}

.icon {
  color: white;
  padding: 10px;
  cursor: pointer;
}

.title {
  color: white;
  font-size: 20px;
  padding: 10px 8px 6px 8px;
}

@media screen and (min-width: 768px) {
  .header {
    display: none;
  }
}