.custom-table {
  width: 100%;
  text-align: left;

  overflow-y: scroll;

  .t-header {
    padding: 0 10px 14px 0;
    display: table-header-group;
    vertical-align: middle;
    border-bottom: 2px solid #dfe1e6;

    color: #5d6b87;
  }

  .custom-table-row {
    padding: 0 10px;
    height: 44px;

    a,
    a:hover,
    a:focus {
      color: #172b4d;
    }

    & > .col {
      display: flex;
      align-items: center;

      a {
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &:hover {
      background-color: #eff0f2 !important;
    }

    &:nth-child(2n) {
      background-color: #f6f7f9;
    }
  }

  .tr-clickable td {
    cursor: pointer;
  }

  .tr-clickable td.up::after {
    content: ' ▲'
  }

  .tr-clickable td.down::after {
    content: ' ▼'
  }
}
